import React, { useEffect } from 'react';
import styles from './NotFoundPage.module.css'; // Adjust the path if necessary

function NotFoundPage() {
    useEffect(() => {
        // Store the original styles
        const originalHtmlStyle = document.documentElement.style.cssText;
        const originalBodyStyle = document.body.style.cssText;
    
        // Set the new styles
        document.documentElement.style.cssText = 'height: 100%; width: 100%; margin: 0;';
        document.body.style.cssText = 'height: 100%; width: 100%; margin: 0; background: linear-gradient(90deg, rgba(47, 54, 64, 1) 23%, rgba(24, 27, 32, 1) 100%);';
    
        // Cleanup to reset styles after the component is unmounted
        return () => {
          document.documentElement.style.cssText = originalHtmlStyle;
          document.body.style.cssText = originalBodyStyle;
        };
      }, []);
  useEffect(() => {
    function drawVisor() {
      const canvas = document.getElementById('visor');
      const ctx = canvas.getContext('2d');

      ctx.beginPath();
      ctx.moveTo(5, 45);
      ctx.bezierCurveTo(15, 64, 45, 64, 55, 45);
      ctx.lineTo(55, 20);
      ctx.bezierCurveTo(55, 15, 50, 10, 45, 10);
      ctx.lineTo(15, 10);
      ctx.bezierCurveTo(15, 10, 5, 10, 5, 20);
      ctx.lineTo(5, 45);

      ctx.fillStyle = '#2f3640';
      ctx.strokeStyle = '#f5f6fa';
      ctx.fill();
      ctx.stroke();
    }

    const cordCanvas = document.getElementById('cord');
    const ctx = cordCanvas.getContext('2d');

    let y1 = 160, y2 = 100, y3 = 100;
    let y1Forward = true, y2Forward = false, y3Forward = true;

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, cordCanvas.width, cordCanvas.height);

      ctx.beginPath();
      ctx.moveTo(130, 170);
      ctx.bezierCurveTo(250, y1, 345, y2, 400, y3);

      ctx.strokeStyle = 'white';
      ctx.lineWidth = 8;
      ctx.stroke();

      if (y1 === 100) y1Forward = true;
      if (y1 === 300) y1Forward = false;

      if (y2 === 100) y2Forward = true;
      if (y2 === 310) y2Forward = false;

      if (y3 === 100) y3Forward = true;
      if (y3 === 317) y3Forward = false;

      y1Forward ? y1++ : y1--;
      y2Forward ? y2++ : y2--;
      y3Forward ? y3++ : y3--;
    }

    drawVisor();
    animate();
  }, []);

  return (
    <>
    <div>
      <div className={styles.moon}></div>
      <div className={`${styles.moon__crater} ${styles.moon__crater1}`}></div>
      <div className={`${styles.moon__crater} ${styles.moon__crater2}`}></div>
      <div className={`${styles.moon__crater} ${styles.moon__crater3}`}></div>

      <div className={`${styles.star} ${styles.star1}`}></div>
      <div className={`${styles.star} ${styles.star2}`}></div>
      <div className={`${styles.star} ${styles.star3}`}></div>
      <div className={`${styles.star} ${styles.star4}`}></div>
      <div className={`${styles.star} ${styles.star5}`}></div>

      <div className={styles.error}>
        <div className={styles.error__title}>404</div>
        <div className={styles.error__subtitle}>Hmmm...</div>
        <div className={styles.error__description}>It looks like one of the developers fell asleep</div>
<button className={`${styles.error__button} ${styles.error__button_active}`}>GO BACK</button>
        {/* <button className={styles.error__button}>CONTACT</button> */}
      </div>

      <div className={styles.astronaut}>
        <div className={styles.astronaut__backpack}></div>
        <div className={styles.astronaut__body}></div>
        <div className={styles.astronaut__body__chest}></div>
        <div className={styles.astronaut__arm_left1}></div>
        <div className={styles.astronaut__arm_left2}></div>
        <div className={styles.astronaut__arm_right1}></div>
        <div className={styles.astronaut__arm_right2}></div>
        <div className={styles.astronaut__arm_thumb_left}></div>
        <div className={styles.astronaut__arm_thumb_right}></div>
        <div className={styles.astronaut__leg_left}></div>
        <div className={styles.astronaut__leg_right}></div>
        <div className={styles.astronaut__foot_left}></div>
        <div className={styles.astronaut__foot_right}></div>
        <div className={styles.astronaut__wrist_left}></div>
        <div className={styles.astronaut__wrist_right}></div>

        <div className={styles.astronaut__cord}>
          <canvas id="cord" height="500px" width="500px"></canvas>
        </div>

        <div className={styles.astronaut__head}>
          <canvas id="visor" width="60px" height="60px"></canvas>
          <div className={styles.astronaut__head_visor_flare1}></div>
          <div className={styles.astronaut__head_visor_flare2}></div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NotFoundPage;
